import React from "react";

const GetQoute = () => {
  return (
    <div>
      <div className="container-xxl bg-white p-0">
        <div className="container-xxl position-relative p-0">
          <div className="container-xxl bg-primary page-header">
            <div className="container text-center">
              <h1 className="text-white animated zoomIn mb-3">Get Quote</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Get Quote
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="container-xxl">
          <div className="container">
            <div
              className="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <div className="d-inline-block border rounded-pill text-primary px-4 mb-3">
                Get Quote
              </div>
              <h2 className="mb-5">Request A Quote</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.3s">
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <select className="form-select" id="floatingSelect">
                          <option value="1">Travel Documents</option>
                          <option value="2">Visa Consultancy</option>
                          <option value="3">
                            International Marriages Consultancy
                          </option>
                          <option value="4">Financial Consultancy</option>
                        </select>
                        <label htmlFor="floatingSelect">
                          Property Transactions in Kenya
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: "150px" }}
                        ></textarea>
                        <label htmlFor="message">Comments</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Request Quote
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Quote End --> */}

        {/* <!-- FOOTER + BACK TO TOP --> */}
      </div>
    </div>
  );
};

export default GetQoute;
