import React from "react";

const Contact = () => {
  return (
    <div>
      <div className="container-xxl bg-white p-0">
        <div className="container-xxl position-relative p-0">
          <div className="container-xxl bg-primary page-header">
            <div className="container text-center">
              <h1 className="text-white animated zoomIn mb-3">Contact Us</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Contact
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="container-xxl">
          <div className="container">
            <div
              className="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <div className="d-inline-block border rounded-pill text-primary px-4 mb-3">
                Contact Us
              </div>
              <h2 className="mb-5">
                If You Have Any Query, Please Feel Free Contact Us
              </h2>
            </div>
            <div className="text-center mb-4">
              <p>
                <i className="fas fa-envelope"></i>{" "}
                info@mvillageglobalconsultancy.com
                <br />
                <i className="fas fa-phone"></i> 254732999799, 254722422769
                <br />
                <i className="fas fa-map-marker-alt"></i> P.0. Box 320 -80403
                Kwale
              </p>
            </div>
            <div className="row justify-content-center g-3">
              <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.3s">
                <iframe
                  title="melo-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d254861.90644571444!2d39.73182284207917!3d-3.5372546527277082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x183fdd0b09499467%3A0xf32195f25a65f294!2sKilifi!5e0!3m2!1sen!2ske!4v1709893280046!5m2!1sen!2ske"
                  width="100%"
                  height="95%"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.3s">
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Subject"
                        />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: "150px" }}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Contact End --> */}

        {/* <!-- FOOTER BACKTOTOP --> */}
      </div>
      {/* <!-- JAVASCRIPT --> */}
    </div>
  );
};

export default Contact;
