import React from "react";

const Home = () => {
  const TestimonialCard = ({ quote, clientName }) => (
    <div className="col-md-6 col-lg-3 mb-4">
      <div className="card text-white border-0 rounded">
        <div className="d-flex align-items-center">
          <div>
            <h6 className="mb-1">{clientName}</h6>
          </div>
        </div>
        <div className="card-body bg-dark p-4 wrap">
          <div className="row">
            <div className="col-12">
              <p className="card-text">
                <i className="fa fa-quote-left text-primary mb-3"></i>
                {quote}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {/* <!-- Navbar & Hero Start --> */}
      <div className="container-xxl position-relative p-0">
        <div className="container-xxl bg-primary hero-header">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 text-center text-lg-start">
                <h1 className="text-white mb-4 animated zoomIn p-2">
                  Your Gateway to Global Journeys
                </h1>
                <p className="text-white pb-3 animated zoomIn">
                  Embark on a journey of a lifetime with our travel and
                  immigration consultancy services. We specialize in creating
                  seamless paths for your global adventures, ensuring a
                  hassle-free experience from dream to destination.
                </p>
                <a
                  href="/quote"
                  className="btn btn-outline-light rounded-pill border-2 py-3 px-5 animated slideInRight"
                >
                  Get a Quote
                </a>
              </div>
              <div className="col-lg-6 text-center text-lg-start">
                <img
                  className="img-fluid animated zoomIn"
                  src="img/hero.png"
                  alt="home"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Navbar & Hero End --> */}

      {/* <!-- About Start --> */}
      <div className="container-xxl">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-1 wow zoomIn" data-wow-delay="0.1s"></div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
              <img
                className="img-fluid rounded h"
                src="img/about.jpg"
                alt="about"
              />
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="d-inline-block border rounded-pill text-primary px-4 mb-3">
                About Us
              </div>
              <h2 className="mb-4">Melavillage globe consultancy</h2>
              <p className="mb-4">
                We are a Travel and immigration consultancy services provider
                founded in December 2019. Officially registered September 2022
                after five years’ experience of working with firms recruiting
                foreign labor for Middle Eastern countries.
              </p>
              <a
                className="btn btn-primary rounded-pill py-3 px-5 mt-2"
                href="/about"
              >
                Read More
              </a>
            </div>
            <div className="col-lg-1 wow zoomIn" data-wow-delay="0.1s"></div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Newsletter Start --> */}
      <div
        className="container-xxl bg-primary my-6 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container px-lg-5">
          <div className="row align-items-center" style={{ height: " 250px" }}>
            <div className="col-12 col-md-6">
              <h3 className="text-white">Ready to get started?</h3>
              <small className="text-white">
                Send us your email address and we will contact you
              </small>
              <div className="position-relative w-100 mt-3">
                <input
                  className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
                  type="text"
                  placeholder="Enter Your Email"
                  style={{ height: " 48px" }}
                />
                <button
                  type="button"
                  className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
                >
                  <i className="fa fa-paper-plane text-primary fs-4"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6 text-center mb-n5 d-none d-md-block">
              <img
                className="img-fluid mt-5"
                style={{ maxHeight: "250px" }}
                src="img/newsletter.png"
                alt="GetStarted"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Newsletter End --> */}

      <div className="container-xxl">
        <div className="container">
          <div
            className="mx-auto text-center wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <div className="d-inline-block border rounded-pill text-primary px-4 mb-3">
              Testimonial
            </div>
            <h2 className="mb-5">What Our Clients Say!</h2>
          </div>
          <div className="row">
            <TestimonialCard
              quote=" I was able to process my documents together with my children's within a short time after consulting Mela Village. They made sure all my documents were correct before we wvisited the embassy and the process was seamlessly easy. Iwas able to migrate successfully to join my husband in Germany."
              clientName="Joy"
            />

            <TestimonialCard
              quote=" When I won my scholarship to study in Dubai, I wasn't sure where to begin. I consulted MelaVillageGlobeConsultancy and they were able to advice me on how to process my documents and I'm currently pursuing my masters in Australia. Thanks MelaVillage!"
              clientName="Martin Irongo"
            />

            <TestimonialCard
              quote=" Mela Village assisted me to process my documents. They're knowledgable and right to the point. I was able to move to Norway without any challenges"
              clientName="Jackqline"
            />

            <TestimonialCard
              quote=" I was able to move and settle in Dubai after consulting MelaVillage. In fact, they connected me to some of their Kenyan clients who have now been my family in Dubai"
              clientName="Faith"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
