import React from "react";

const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark text-light footer pt-5 wow fadeIn"
      data-wow-delay="0.1s"
      style={{ marginTop: "6rem" }}
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <h5 className="text-white mb-4">Newsletter</h5>
            <p>
              Stay updated with our latest news and offers by subscribing to our
              newsletter.
            </p>

            <div className="position-relative w-100 mt-3">
              <input
                className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
                type="text"
                placeholder="Your Email"
                style={{ height: "48px" }}
              />
              <button
                type="button"
                className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
              >
                <i className="fa fa-paper-plane text-primary fs-4"></i>
              </button>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-2">
            <h5 className="text-white mb-4">Quick Links</h5>
            <a className="btn btn-link" href="/about">
              About us
            </a>
            <a className="btn btn-link" href="/services">
              Our Services
            </a>
            <a className="btn btn-link" href="/quote">
              Get Quote
            </a>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p>
              <i className="fa fa-map-marker-alt me-3"></i>P.0. Box 320 -80403
              Kwale
            </p>
            <p>
              <i className="fa fa-phone-alt me-3"></i>254732999799. 254722422769
            </p>
            <p>
              <i className="fa fa-envelope me-3"></i>
              info@mvillageglobalconsultancy.com
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="facebook">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="twiter">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="youtube">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="instagram">
                <i className="fab fa-instagram"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="linkedin">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="text-center">
            &copy; Mellavillage | All Right Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
