import React from "react";

const Services = () => {
  return (
    <div>
      <div className="container-xxl bg-white p-0">
        <div className="container-xxl position-relative p-0">
          <div className="container-xxl bg-primary page-header">
            <div className="container text-center">
              <h1 className="text-white animated zoomIn">Services</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Services
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="container-xxl">
          <div className="container">
            <div
              className="mx-auto text-center wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <div className="d-inline-block border rounded-pill text-primary px-4 mb-3">
                Our Services
              </div>
              <h2 className="mb-5">We Provide Solutions On Your Business</h2>
            </div>
            <div className="row g-4">
              <div
                className="col-lg-6 col-md-12 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item rounded h-100">
                  <div className="d-flex justify-content-between">
                    <div className="service-icon">
                      <i className="fas fa-passport fa-2x"></i>
                    </div>
                  </div>
                  <div className="p-5">
                    <h5 className="mb-3">Travel Documents</h5>
                    <span>
                      Navigating the complexities of travel documentation is our
                      expertise. Whether you need assistance with Kenyan
                      passports, vaccinations, health alerts, police clearance
                      certificates, or any other pertinent information, we've
                      got you covered.
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <div className="service-item rounded h-100">
                  <div className="d-flex justify-content-between">
                    <div className="service-icon">
                      <i className="fas fa-plane fa-2x"></i>
                    </div>
                  </div>
                  <div className="p-5">
                    <h5 className="mb-3">Visa Consultancy</h5>
                    <span>
                      Our visa consultancy services encompass a wide array of
                      needs, including Kenyan K visas for retired individuals,
                      work permits, investor visas, permanent residency,
                      dependents' visas, and guidance on foreign travel visa
                      requirements and extensions.
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <div className="service-item rounded h-100">
                  <div className="d-flex justify-content-between">
                    <div className="service-icon">
                      <i className="fas fa-ring fa-2x"></i>
                    </div>
                  </div>
                  <div className="p-5">
                    <h5 className="mb-3">
                      International Marriages Consultancy
                    </h5>
                    <span>
                      Ensure your union meets all legal requirements with our
                      consultancy on international marriages. We provide
                      guidance on overcoming any marriage impediments, adhering
                      to Kenyan and international law, and obtaining necessary
                      documents such as birth certificates for children born
                      abroad.
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item rounded h-100">
                  <div className="d-flex justify-content-between">
                    <div className="service-icon">
                      <i className="fas fa-home fa-2x"></i>
                    </div>
                  </div>
                  <div className="p-5">
                    <h5 className="mb-3">Property Transactions in Kenya</h5>
                    <span>
                      Facilitating property transactions in Kenya is our
                      specialty. From registration and acquisition of Kenyan Tax
                      Pins to tax return filing, land searches, and property
                      transfers, we offer comprehensive support. Additionally,
                      we assist in the sale of expatriate goods, vehicles, and
                      properties, as well as aid expatriates in settlement and
                      networking with NGOs and foreign volunteers.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
