import React from "react";

const About = () => {
  return (
    <div>
      <div className="container-xxl bg-white p-0">
        {/* <!-- Navbar & Hero Start --> */}
        <div className="container-xxl position-relative p-0">
          <div className="container-xxl bg-primary page-header">
            <div className="container text-center">
              <h1 className="text-white animated zoomIn mb-3">About Us</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>

                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* <!-- Navbar & Hero End --> */}

        {/* <!-- About Start --> */}
        <div className="container-xxl">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow zoomIn" data-wow-delay="0.1s">
                <img className="img-fluid" src="img/about.jpg" alt="About" />
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="d-inline-block border rounded-pill text-primary px-4 mb-3">
                  About Us
                </div>
                <h2 className="mb-4">Melavillage globe consultancy</h2>
                <p className="mb-4">
                  We are a Travel and immigration consultancy services provider
                  founded in December 2019. Officially registered September 2022
                  after five years’ experience of working with firms recruiting
                  foreign labor for Middle Eastern countries.
                </p>
                <p>
                  {" "}
                  The effects of Covid 19 Lockdown that forced the disruption of
                  travel industry was a key reason to diversification of our
                  services to be able to address the needs of thousands of
                  foreign migrants workers, tourist and visitors who were unable
                  to travel
                </p>
                <div className="row g-3 mb-4">
                  <div className="col-12 d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                      <i className="fa fa-bullseye text-white"></i>
                    </div>
                    <div className="ms-4">
                      <h6>Our Motto</h6>
                      <span>One Globe one Village</span>
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                      <i className="fa fa-quote-left text-white"></i>
                    </div>
                    <div className="ms-4">
                      <h6>Our Mission</h6>
                      <span>
                        Creating a linkage and access to global opportunities
                        through Innovation, Travel, Migration and easy
                        settlement in adopted countries.
                      </span>
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                      <i className="fa fa-eye text-white"></i>
                    </div>
                    <div className="ms-4">
                      <h6>Our Vision</h6>
                      <span>
                        Be a leading Consultancy in Africa with Linkages of
                        Global Opportunities.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- About End --> */}

        {/* <!-- Features Start --> */}
        <div className="container-xxl py-6">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="d-inline-block border rounded-pill text-primary px-4 mb-3">
                  Strategy
                </div>
                <h2 className="mb-4">Our Strategic Approach</h2>
                <p>
                  At the core of our success lies a robust strategy, intricately
                  woven around our mission. This strategic roadmap propels us
                  towards a prosperous future, aligning seamlessly with our
                  long-term vision.
                </p>
                <p>
                  To ensure the effective execution of our strategy, we've
                  identified four key priorities:
                </p>
              </div>
              <div className="col-lg-7">
                <div className="row g-5">
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0 btn-square bg-primary rounded-circle me-3">
                        <i className="fa fa-flag-checkered text-white"></i>
                      </div>
                      <h6 className="mb-0">Innovative Integrity:</h6>
                    </div>
                    <span>
                      Upholding the highest ethical standards to foster trust.
                    </span>
                  </div>
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0 btn-square bg-primary rounded-circle me-3">
                        <i className="fa fa-globe text-white"></i>
                      </div>
                      <h6 className="mb-0">Global Network Prowess:</h6>
                    </div>
                    <span>
                      Building connections worldwide to expand our reach.
                    </span>
                  </div>
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0 btn-square bg-primary rounded-circle me-3">
                        <i className="fa fa-clock text-white"></i>
                      </div>
                      <h6 className="mb-0">Timely Intervention:</h6>
                    </div>
                    <span>
                      Ensuring swift and impactful actions at critical
                      junctures.
                    </span>
                  </div>
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0 btn-square bg-primary rounded-circle me-3">
                        <i className="fa fa-cogs text-white"></i>
                      </div>
                      <h6 className="mb-0">Simplified Process:</h6>
                    </div>
                    <span>
                      Streamlining operations for enhanced efficiency.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Features End --> */}
      </div>
    </div>
  );
};

export default About;
